













import {Vue, Component, Watch} from 'vue-property-decorator'
import ClickOutside from '@/lib/ClickOutside.vue'
import Utils from '@/utils'
import {Getter} from 'vuex-class'
import {FieldConfigs} from '@/lib/kepler/interfaces'

@Component({
  components: {
    ClickOutside,
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    MapFilters: Utils.loadComponent('filters/MapFilters'),
    MapFiltersButton: Utils.loadComponent('MapFiltersButton'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class FiltersContainer extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  public show = false

  public close() {
    this.show = false
  }

  @Watch('show', {})
  public onShowChange(v: boolean) {
    if (v) {
      this.$emit('click')
    }
  }
}
