





























































































import {Component, Prop, Watch} from 'vue-property-decorator'
import {TocElement, TocResponse} from '@/lib/kepler/interfaces'
import Validations from '@/lib/Validations'
import Utils from '@/utils'

import Contracts from '@/views/Contracts.vue'

@Component({
  components: {
    CardList: Utils.loadComponent('CardList'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  name: 'RegistrationFieldTos',
})
export default class RegistrationFieldTos extends Contracts {

  @Prop() public acceptTosCallback!: () => void
  @Prop({
    type: Array,
    default: () => [],
  }) public filter!: string[]

  public internalValue: string[] = []

  public rules = Validations.rules
  public detailItem: null | TocElement = null

  public get tocs(): TocResponse[] {
    return this.termOfServices.tocs || []
  }

  public get missingTocs() {
    const missing: string[] = []
    const tocGroups = this.tocs
    tocGroups.forEach((tocGroup) => {
      tocGroup.tocs.forEach((toc) => {
        if (toc.status === 'MISSING' && !toc.toc.optional) {
          missing.push(toc.toc.id)
        }
      })
    })
    if (this.internalValue) {
      return missing.filter((id) => !this.internalValue.includes(id))
    }
    return missing
  }

  public setDetail(item: TocElement) {
    this.detailItem = item
  }

  public get detailModal() {
    return !!this.detailItem
  }

  // noinspection JSUnusedGlobalSymbols, used in v-model
  public set detailModal(v: boolean) {
    if (!v) {
      this.detailItem = null
    }
  }

  @Watch('internalValue')
  public onValueChange(val: string[] | string | null) {
    if (val === null) {
      val = []
    }
    if (typeof val === 'string') {
      val = [val]
    }
    this.$emit('input', val)
    this.$nextTick(() => {
      (this.$refs.validationProvider as any).validate()
    })
  }

  public wasApproved(id: string): boolean {
    return this.internalValue.includes(id)
  }

  public canSign(detailItem: TocElement) {
    if (this.filter && this.wasApproved(detailItem.toc.id)) {
      return false
    } else {
      // 'SIGNED' | 'MISSING' | 'EXPIRED' | 'UPCOMING'
      return detailItem.status === 'MISSING' || detailItem.status === 'UPCOMING'
    }
  }

  public canUnsign(detailItem: TocElement) {
    const remoteApproved = detailItem.signed_on !== null
    const localApproved = !this.filter && this.wasApproved(detailItem.toc.id)

    return detailItem.toc.optional && (remoteApproved || localApproved)
  }

  protected signAndCloseModal(id: string) {
    if (this.filter) {
      // @ts-ignore
      this.sign(id).then(() => {
        this.detailItem = null
      })
    } else if (!this.internalValue.includes(id)) {
      this.internalValue.push(id)
      this.detailItem = null
    }
  }

  protected unsignAndCloseModal(id: string) {
    if (this.filter) {
      this.unsign(id).then(() => {
        this.detailItem = null
      })
    } else if (this.internalValue.includes(id)) {
      const i = this.internalValue.findIndex((internalId: string) => internalId === id)
      this.internalValue.splice(i, 1)
      this.detailItem = null
    }
  }

  protected tocColor(item: TocElement) {
    if (this.filter) {
      return this.parseStatusClass(item.status, item.toc.optional)
    } else {
      if (item.toc.optional && !this.wasApproved(item.toc.id)) {
        return 'orange--text'
      }
      return this.wasApproved(item.toc.id) ? 'success--text' : 'error--text'
    }
  }

  protected checkBoxLabel(item: TocElement) {
    return item.toc.acceptance_statement
  }

  protected mounted() {
    this.internalValue = []
    this.$nextTick(() => {
      (this.$refs.validationProvider as any).validate()
    })
  }
}
