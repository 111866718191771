import { render, staticRenderFns } from "./CarouselContainer.vue?vue&type=template&id=d5b9751e&lang=pug&"
import script from "./CarouselContainer.vue?vue&type=script&lang=ts&"
export * from "./CarouselContainer.vue?vue&type=script&lang=ts&"
import style0 from "./CarouselContainer.vue?vue&type=style&index=0&id=d5b9751e&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports