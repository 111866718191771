











































































import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import moment from 'moment'
import Utils from '@/utils'
import {TocElement, TocSignResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Icon: Utils.loadComponent('proxy/Icon'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Button: Utils.loadComponent('Button'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
  name: 'Contracts',
})
export default class Contracts extends Vue {
  @State('termOfServices') public termOfServices!: TermOfServicesState
  @Action('getTocs') public getTocs!: () => void
  @Action('getToc') public getToc!: (id: string) => Promise<TocElement>
  @Action('signToc') public signToc!: (id: string) => Promise<TocSignResponse>
  @Action('unsignToc') public unsignToc!: (id: string) => Promise<any>

  public loading: boolean = false
  public downloadedTocs: Set<string> = new Set<string>()
  public singleTocData: TocElement | null = null

  public get singleTocId() {
    if (this.$route.hash) {
      const toc = this.$route.hash.replace('#', '')
      this.getSingleToc(toc)
      return toc
    }
    return null
  }

  public get translatedAttachments() {
    const l = this.$currentLang()
    const result: Record<string, string> = {}

    this.termOfServices.tocs?.forEach(({tocs}) => {
      tocs.forEach((t) => {
        const tr = t.toc.attachment_translations
        if (tr && tr[l]) {
          result[t.toc.id] = tr[l]
        }
      })
    })
    return result
  }

  public getSingleToc(id: string) {
    this.getToc(id).then((r: TocElement) => {
      this.singleTocData = r
    })
  }

  public formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY')
  }

  public parseStatusClass(status: string, optional: boolean) {
    switch (status) {
      case 'EXPIRED':
        return 'grey--text'
      case 'SIGNED':
        return 'success--text'
      case 'UPCOMING':
        return 'warning--text'
      case 'MISSING':
        return optional ? 'warning--text' : 'error--text'
    }
  }

  public approvable(status: string) {
    // 'SIGNED' | 'MISSING' | 'EXPIRED' | 'UPCOMING'
    return status === 'MISSING' || status === 'UPCOMING'
  }

  public fileName(path: string) {
    return path.split(/[\s/]+/).pop()
  }

  public downloadToc(el: TocElement) {
    if (el.toc.attachment) {
      cordova.InAppBrowser.open(el.toc.attachment, '_system')
      this.downloadedTocs.add(el.toc.id)
    }
  }

  public downloadTranslatedToc(el: TocElement) {
    const tr = el.toc.attachment_translations?.[this.$currentLang()]
    if (tr) {
      cordova.InAppBrowser.open(tr, '_system')
      this.downloadedTocs.add(el.toc.id)
    }
  }

  public wasDownloaded(el: TocElement) {
    if (el.toc.attachment) {
      return this.downloadedTocs.has(el.toc.id)
    }
    return true
  }

  public sign(id: string) {
    this.loading = true
    return this.signToc(id).finally(() => {
      this.loading = false
    })
  }

  public unsign(id: string) {
    this.loading = true
    return this.unsignToc(id).finally(() => {
      this.loading = false
    })
  }

  protected created() {
    this.getTocs()
  }
}
